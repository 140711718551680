import React from "react"
import { Row, Modal } from "react-bootstrap"

import PanelInner from "@/_components/PanelInner"
import CustomButton from "@/_components/CustomButton"
import FormInputExtended from "@/_components/FormInputExtended"
import ButtonWithTooltip from "@/_components/ButtonWithTooltip"
import { checkErrors } from "basikon-common-utils"
import { loc } from "@/_services/localization"

export class PersonEditBankAccountsModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      prevBankAccounts: [...(props.person.bankAccounts || [{}])],
    }
  }

  handleClose = () => {
    const { handleSetPersonState, onClose } = this.props
    const { prevBankAccounts } = this.state
    handleSetPersonState({ bankAccounts: prevBankAccounts })
    onClose()
  }

  handleSave = () => {
    const { person, handleSetPersonState } = this.props
    if (checkErrors(person, handleSetPersonState)) return

    const { onClose } = this.props
    onClose()
  }

  handleBankAccountAction = (event, action, index) => {
    const { handleSetPersonState, person } = this.props
    const copyBankAccounts = (person.bankAccounts || []).map(bankAccount => ({ ...bankAccount })) // Copy to keep prevState

    if (action === "add") copyBankAccounts.push({})
    else if (action === "delete") copyBankAccounts.splice(index, 1)
    else if (action === "setFirst") {
      event.stopPropagation()

      const bankAccount = copyBankAccounts[0]
      copyBankAccounts[0] = copyBankAccounts[index]
      copyBankAccounts[index] = bankAccount
    }

    handleSetPersonState({ bankAccounts: copyBankAccounts })
  }

  render() {
    const {
      person,
      readOnly,
      showSort = true,
      showSepaMandates,
      showDelete = true,
      handleSetPersonState,
      showSepaMandateAdd = true,
      showSepaMandateDelete = true,
      showCollectionMethods = false,
      showSepaMandateDuplicate = true,
      showPreferredWithdrawalDate = true,
    } = this.props

    let bankAccounts = [...(person.bankAccounts || [])]
    if (!bankAccounts || bankAccounts.length === 0) bankAccounts = [{}]

    return (
      <Modal show={true} onHide={() => this.handleClose()} bsSize="large" backdrop="static" className="person-edit-bank-accounts-modal">
        <Modal.Header closeButton>
          <Modal.Title>{loc`Bank accounts`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {bankAccounts.map((bankAccount, index) => {
            const handleSetPersonBankAccountState = patch => {
              if (!patch) return
              bankAccounts[index] = { ...(bankAccounts[index] || {}), ...patch }

              if (bankAccounts[index].bankIban && !bankAccounts[index].name) {
                if (person?.type === "C" && person?.name) bankAccounts[index].name = person.name
                else if (person?.type === "I" && (person?.firstName || person?.lastName))
                  bankAccounts[index].name = `${person.firstName || ""} ${person.lastName || ""}`.trim()
              }
              handleSetPersonState({ bankAccounts })
            }

            return (
              <PanelInner
                key={index}
                collapse={bankAccount.isInactive ? true : false}
                title={
                  <>
                    {!bankAccount.isInactive && bankAccount.name}
                    {bankAccount.isInactive && <strike>{bankAccount.name}</strike>}
                    {index > 0 && (
                      <>
                        {showDelete && (
                          <ButtonWithTooltip
                            simple
                            pullRight
                            bsSize="xs"
                            bsStyle="danger"
                            btnClassName="flex-center"
                            className="icn-xmark icn-xs"
                            onClick={event => this.handleBankAccountAction(event, "delete", index)}
                          />
                        )}
                        {showSort && (
                          <CustomButton
                            bsSize="xs"
                            simple
                            pullRight
                            className="flex-center"
                            iconClassName="icn-arrow-up icn-xs"
                            label="Make it first/default"
                            onClick={event => this.handleBankAccountAction(event, "setFirst", index)}
                          />
                        )}
                      </>
                    )}
                  </>
                }
              >
                <Row>
                  <FormInputExtended
                    type="bankAccount"
                    obj={bankAccount}
                    readOnly={readOnly}
                    onSetState={handleSetPersonBankAccountState}
                    fields={[
                      { field: "name", mandatory: true, colProps: { xs: 12, md: 10 } },
                      { field: "isInactive", type: "checkbox", label: "Inactive", colProps: { xs: 12, md: 2 } },
                      { field: "bankIban", debounce: true, colProps: { xs: 12, sm: 6 } },
                      { field: "bankBic", colProps: { xs: 12, sm: 3 } },
                      { field: "bankName", colProps: { xs: 12, sm: 3 } },
                      { field: "bankOwner", colProps: { xs: 12, sm: 6 } },
                      { field: "bankOpeningDate", colProps: { xs: 12, sm: 3 } },
                      { field: "bankPreferredWithdrawalDate", hidden: !showPreferredWithdrawalDate, colProps: { xs: 12, sm: 3 } },
                      { field: "externalReference", colProps: { xs: 12, sm: 3 } },
                      { field: "sepaCreditorIdentifier", label: "SEPA creditor identifier", colProps: { xs: 12, sm: 3 } },
                      {
                        field: "collectionMethods",
                        hidden: !showCollectionMethods,
                        multiple: true,
                        select: "paymentMode",
                        colProps: { xs: 12, sm: 6 },
                      },
                      { field: "sepaMandates", hidden: !showSepaMandates, showSepaMandateAdd, showSepaMandateDelete, showSepaMandateDuplicate },
                    ]}
                  />
                </Row>
              </PanelInner>
            )
          })}
          {!readOnly && (
            <legend>
              <CustomButton
                fill
                bsStyle="primary"
                bsSize="small"
                className="flex-center"
                onClick={event => this.handleBankAccountAction(event, "add")}
              >
                <i className="icn-plus icn-xs mr-5px" />
                {loc("Add")}
              </CustomButton>
            </legend>
          )}
        </Modal.Body>
        <Modal.Footer>
          <CustomButton bsSize="sm" onClick={() => this.handleClose()} label="Close" />
          <CustomButton bsStyle="primary" bsSize="sm" fill onClick={() => this.handleSave()} label="Save" hidden={readOnly} />
        </Modal.Footer>
      </Modal>
    )
  }
}

export default PersonEditBankAccountsModal
