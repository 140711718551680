import { dateNoTime } from "basikon-common-utils"
import React, { Component } from "react"
import { Col, Modal, Row } from "react-bootstrap"

import CustomButton from "@/_components/CustomButton"
import FormInput from "@/_components/FormInput"

import { loc } from "@/_services/localization"

class InventoriesModal extends Component {
  constructor(props) {
    super(props)
    const today = new Date()
    this.state = { date: dateNoTime(new Date(today.getFullYear(), 2 + today.getMonth(), -1)) }
  }

  handleClose = isSave => {
    this.props.onClose(isSave && this.state.date)
  }

  handleSetState = patch => {
    this.setState(patch)
  }

  render() {
    return (
      <Modal show={true} onHide={this.handleClose} backdrop="static" className="inventories-modal">
        <Modal.Header closeButton>
          <Modal.Title>{loc`Edit parameters`}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col xs={12}>
              <FormInput bsClass="form-control" obj={this.state} field="date" type="date" onSetState={this.handleSetState} />
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <CustomButton bsSize="sm" onClick={() => this.handleClose()}>
            {loc`Close`}
          </CustomButton>

          <CustomButton bsStyle="primary" bsSize="sm" fill onClick={() => this.handleClose(true)}>
            {loc`Execute`}
          </CustomButton>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default InventoriesModal
