import React from "react"
import { Col, Row } from "react-bootstrap"

import CustomButton from "@/_components/CustomButton"
import FormInput from "@/_components/FormInput"
import FormInputExtended from "@/_components/FormInputExtended"
import LayoutCard from "@/_components/LayoutCard"
import PanelInner from "@/_components/PanelInner"

import AddressesPanel from "@/person/AddressesPanel"
import AddressPanel from "@/person/AddressPanel"
import PersonContact from "@/person/PersonContact"
import PersonRegistrationComponent from "@/person/PersonRegistrationComponent"

import { loc } from "@/_services/localization"
import { personTypes } from "@/_services/personUtils"
import { generateRows } from "@/_services/utils"

export default class PersonGlobal extends React.Component {
  setCompany = patch => {
    const { person, handleSetPersonState } = this.props
    person.company = person.company || {}
    person.company = {
      ...person.company,
      ...patch,
    }

    handleSetPersonState({ company: person.company })
  }

  setWeb = patch => {
    const { person, handleSetPersonState } = this.props
    person.web = person.web || {}
    person.web = {
      ...person.web,
      ...patch,
    }

    handleSetPersonState({ web: person.web })
  }

  getField = col => {
    const { name, colProps, formInputProps, hidden, modalRows } = col
    if (hidden) return
    const {
      person = {},
      detailed,
      addressExtraInfo,
      addressLanguage,
      addressRegion,
      readOnly,
      modelPath,
      allowDuplicates,
      handleSetPersonState,
      handleSetPersonAddressState,
      handleSetPersonBankAccountState,
      onShowEditBankAccountsModal,
      showRegistrationConfirmModal,
      isProspect,
      registrationProps = {},
      showSepaMandates,
      fromProspectOrPersonPage,
      showEdit,
      showAddressOpenModalButton,
      showBankOpenModalButton = true,
      personRegistrationsModal,
    } = this.props

    const isPro = person.type === personTypes.PROFESSIONAL
    const address = person.addresses?.[0] || {}
    const bankAccount = person.bankAccounts?.[0] || {}
    const { company } = person || {}
    const { web } = person || {}
    const showBankAccounts = !person.props_bankAccounts || !person.props_bankAccounts.hidden
    const showPersonAddress = !person.props_address || !person.props_address.hidden
    const showPersonAddresses = person.props_addresses && person.props_addresses.hidden === false
    const showContact = [personTypes.COMPANY, personTypes.ORGANIZATION].includes(person.type)
      ? !person.props_companyContact || !person.props_companyContact.hidden
      : isPro
      ? person.props_proContact && person.props_proContact.hidden === false
      : person.props_individualContact && person.props_individualContact.hidden === false
    const showBic = !person.props_bankBic || !person.props_bankBic.hidden
    const showBankName = !person.props_bankName || !person.props_bankName.hidden
    const showBankAccountOwner = !person.props_bankAccountOwner || !person.props_bankAccountOwner.hidden
    const showBankAccountOpeningDate = !person.props_bankAccountOpeningDate || !person.props_bankAccountOpeningDate.hidden
    const showPreferredWithdrawalDate = !person.props_bankPreferredWithdrawalDate || !person.props_bankPreferredWithdrawalDate.hidden
    const showSecondName = !person.props_secondName || !person.props_secondName.hidden
    const showPhone = (!readOnly || person.phone) && (!person.props_phone || !person.props_phone.hidden)
    const showRegistration = person.props_registration?.hidden !== true
    const showAddressLine = !person.props_addressLine || !person.props_addressLine.hidden
    const showLastName = !person.props_lastName || !person.props_lastName.hidden

    const isRegistrationDisabled = person.props_registration?.disabled === true
    const showName = !person.props_name || !person.props_name.hidden
    const showCurrency = person.props_currency?.hidden === false && this.props.currencies?.length
    const showMobile = !readOnly || person.mobile
    const showEmail = !readOnly || person.email

    let personTitleMd = 1
    if (!showSecondName) personTitleMd += 1

    let firstNameMd = isPro ? 3 : 2
    let lastNameMd = 3
    if (!showSecondName) {
      firstNameMd += 1
      lastNameMd += 1
    }
    if (!showRegistration) {
      firstNameMd += 1
      lastNameMd += 1
    }

    const registrationColProps = person.props_registration?.colProps || { xs: 12, sm: 6, md: isPro ? 2 : 3 }

    const personProps = { key: name, readOnly, modelPath, obj: person, onSetState: handleSetPersonState }
    const companyProps = { ...personProps, obj: company, onSetState: this.setCompany }
    const webProps = { ...personProps, obj: web, onSetState: this.setWeb }

    if (name === "registration")
      return (
        showRegistration && (
          <PersonRegistrationComponent
            key={name}
            readOnly={readOnly}
            person={person}
            personType={person.type}
            label="Registration number"
            onSetState={handleSetPersonState}
            modelPath={modelPath}
            allowDuplicates={allowDuplicates}
            colProps={colProps ?? registrationColProps}
            disabled={isRegistrationDisabled}
            showRegistrationConfirmModal={showRegistrationConfirmModal}
            isProspect={isProspect}
            {...registrationProps}
            {...(person?.props_registration || {})}
            fromProspectOrPersonPage={fromProspectOrPersonPage}
            showEdit={showEdit}
            {...formInputProps}
            modalRows={modalRows}
            personRegistrationsModal={personRegistrationsModal}
          />
        )
      )
    else if (name === "title")
      return (
        <FormInput
          key={name}
          field="title"
          select="personTitle"
          colProps={colProps ?? { xs: 12, md: personTitleMd }}
          {...personProps}
          {...formInputProps}
        />
      )
    else if (name === "firstName")
      return (
        <FormInput key={name} debounce field="firstName" colProps={colProps ?? { xs: 12, md: firstNameMd }} {...personProps} {...formInputProps} />
      )
    else if (name === "lastName")
      return (
        showLastName && (
          <FormInput
            key={name}
            mandatory
            debounce
            field="lastName"
            colProps={colProps ?? { xs: 12, md: lastNameMd }}
            {...personProps}
            {...formInputProps}
          />
        )
      )
    else if (name === "secondName")
      return (
        showSecondName && (
          <FormInput key={name} debounce field="secondName" colProps={colProps ?? { xs: 12, md: lastNameMd }} {...personProps} {...formInputProps} />
        )
      )
    else if (name === "phone")
      return (
        showPhone && (
          <FormInput key={name} debounce field="phone" type="phone" colProps={colProps ?? { xs: 12, sm: 3 }} {...personProps} {...formInputProps} />
        )
      )
    else if (name === "mobile")
      return (
        showMobile && (
          <FormInput key={name} debounce field="mobile" type="phone" colProps={colProps ?? { xs: 12, sm: 3 }} {...personProps} {...formInputProps} />
        )
      )
    else if (name === "email")
      return (
        showEmail && (
          <FormInput key={name} debounce field="email" type="email" colProps={colProps ?? { xs: 12, sm: 3 }} {...personProps} {...formInputProps} />
        )
      )
    else if (name === "preferredLanguage")
      return (
        <FormInput
          key={name}
          field="preferredLanguage"
          select="preferredLanguage"
          colProps={colProps ?? { xs: 12, sm: 3 }}
          {...personProps}
          {...formInputProps}
        />
      )
    else if (name === "addressLine")
      return (
        showAddressLine &&
        !detailed && (
          <FormInputExtended
            key={name}
            obj={address}
            type="address"
            readOnly={readOnly}
            extraInfo={addressExtraInfo}
            language={addressLanguage}
            region={addressRegion}
            onSetState={handleSetPersonAddressState}
            colProps={colProps ?? { xs: 12 }}
            modelPath={modelPath}
            {...formInputProps}
          />
        )
      )
    else if (name === "birthDate")
      return (
        <FormInput
          key={name}
          field="birthDate"
          isBirthDate
          type="date"
          colProps={colProps ?? { xs: 12, sm: 3, className: detailed ? "" : "d-none" }}
          {...personProps}
          {...formInputProps}
        />
      )
    else if (name === "birthPlace")
      return (
        <FormInput
          key={name}
          field="birthPlace"
          colProps={colProps ?? { xs: 12, sm: 3, className: detailed ? "" : "d-none" }}
          {...personProps}
          {...formInputProps}
        />
      )
    else if (name === "birthZipcode")
      return (
        <FormInput
          key={name}
          field="birthZipcode"
          colProps={colProps ?? { xs: 12, sm: 3, className: detailed ? "" : "d-none" }}
          {...personProps}
          {...formInputProps}
        />
      )
    else if (name === "birthCountry")
      return (
        <FormInput
          key={name}
          field="birthCountry"
          select="country"
          colProps={colProps ?? { xs: 12, sm: 3, className: detailed ? "" : "d-none" }}
          {...personProps}
          {...formInputProps}
        />
      )
    else if (name === "nationality")
      return (
        <FormInput
          key={name}
          field="nationality"
          select="country"
          colProps={colProps ?? { xs: 12, sm: 3, className: detailed ? "" : "d-none" }}
          {...personProps}
          {...formInputProps}
        />
      )
    else if (name === "contacts")
      return (
        showContact && (
          <Col key={name} xs={12} {...(colProps || {})}>
            <PersonContact
              collapse={false}
              defaultValues={{ lastName: person.lastName, firstName: person.firstName }}
              contacts={person.contacts}
              readOnly={readOnly}
              handleSetPersonState={handleSetPersonState}
              show={detailed}
              {...formInputProps}
            />
          </Col>
        )
      )
    else if (name === "address")
      return (
        showPersonAddress && (
          <Col key={name} xs={12} {...(colProps || {})}>
            <AddressPanel
              entity={person}
              handleSetEntityState={handleSetPersonState}
              readOnly={readOnly}
              extraInfo={addressExtraInfo}
              language={addressLanguage}
              region={addressRegion}
              modelPath={modelPath}
              show={detailed}
              showOpenModalButton={showAddressOpenModalButton}
            />
          </Col>
        )
      )
    else if (name === "addresses")
      return (
        showPersonAddresses && (
          <Col key={name} xs={12} {...(colProps || {})}>
            <AddressesPanel
              entity={person}
              handleSetEntityState={handleSetPersonState}
              readOnly={readOnly}
              extraInfo={addressExtraInfo}
              language={addressLanguage}
              region={addressRegion}
              modelPath={modelPath}
              show={detailed}
              showOpenModalButton={showAddressOpenModalButton}
            />
          </Col>
        )
      )
    else if (name === "bankAccounts")
      return (
        showBankAccounts && (
          <Col key={name} xs={12} {...(colProps || {})}>
            <PanelInner
              collapse={false}
              title="Banking information"
              className={detailed ? "" : "d-none"}
              buttons={
                showBankOpenModalButton && (
                  <CustomButton
                    bsStyle="primary"
                    bsSize="xs"
                    fill
                    className="pull-right flex-center"
                    data-test="edit-person-banking-info-btn"
                    onClick={onShowEditBankAccountsModal}
                  >
                    <i className={`icn-${readOnly ? "eye" : "edit"} icn-xs`} />
                  </CustomButton>
                )
              }
            >
              <Row>
                <FormInputExtended
                  type="bankAccount"
                  obj={bankAccount}
                  readOnly={readOnly}
                  onSetState={handleSetPersonBankAccountState}
                  modelPath={(modelPath ? `${modelPath}.` : "") + "bankAccounts[0]"}
                  fields={[
                    { field: "bankIban", debounce: true, colProps: { xs: 12, sm: 5 } },
                    { field: "bankBic", hidden: !showBic, colProps: { xs: 12, sm: 3 } },
                    { field: "bankName", hidden: !showBankName, colProps: { xs: 12, sm: 4 } },
                    { field: "bankOwner", hidden: !showBankAccountOwner, colProps: { xs: 12, sm: 5 } },
                    { field: "bankOpeningDate", hidden: !showBankAccountOpeningDate, colProps: { xs: 12, sm: 3 } },
                    { field: "bankPreferredWithdrawalDate", hidden: !showPreferredWithdrawalDate, colProps: { xs: 12, sm: 4 } },
                    { field: "sepaMandates", hidden: !showSepaMandates },
                  ]}
                />
              </Row>
            </PanelInner>
          </Col>
        )
      )
    else if (name === "familyMaritalStatus")
      return (
        <FormInput
          key={name}
          field="familyMaritalStatus"
          label={loc`Marital status`}
          select="maritalStatus"
          colProps={colProps ?? { xs: 12, sm: 6 }}
          {...personProps}
          {...formInputProps}
        />
      )
    else if (name === "familyMaritalContract")
      return (
        <FormInput
          key={name}
          field="familyMaritalContract"
          label={loc`Marital contract`}
          select="maritalContract"
          colProps={colProps ?? { xs: 12, sm: 6 }}
          {...personProps}
          {...formInputProps}
        />
      )
    else if (name === "familyNumberOfDependents")
      return (
        <FormInput
          key={name}
          field="familyNumberOfDependents"
          label={loc`Number of dependents`}
          type="number"
          colProps={colProps ?? { xs: 12, sm: 6 }}
          {...personProps}
          {...formInputProps}
        />
      )
    else if (name === "employmentJobDescription")
      return (
        <FormInput
          key={name}
          field="employmentJobDescription"
          label={loc`Job description`}
          select="jobDescription"
          colProps={colProps ?? { xs: 12, sm: person?.props_employmentSector?.hidden ? 12 : 6 }}
          {...personProps}
          {...formInputProps}
        />
      )
    else if (name === "employmentSector")
      return (
        <FormInput
          key={name}
          field="employmentSector"
          select="employmentSector"
          colProps={colProps ?? { xs: 12, sm: person?.props_employmentJobDescription?.hidden ? 12 : 6 }}
          {...personProps}
          {...formInputProps}
        />
      )
    else if (name === "employmentContractType")
      return (
        <FormInput
          key={name}
          field="employmentContractType"
          select="employmentContractType"
          label={loc`Contract type`}
          colProps={colProps ?? { xs: 12, sm: person?.props_employmentStartDate?.hidden ? 12 : 6 }}
          {...personProps}
          {...formInputProps}
        />
      )
    else if (name === "employmentStartDate")
      return (
        <FormInput
          key={name}
          field="employmentStartDate"
          type="date"
          onSetState={handleSetPersonState}
          colProps={colProps ?? { xs: 12, sm: person?.props_employmentContractType?.hidden ? 6 : 3 }}
          {...personProps}
          {...formInputProps}
        />
      )
    else if (name === "employmentEndDate")
      return (
        <FormInput
          key={name}
          field="employmentEndDate"
          type="date"
          onSetState={handleSetPersonState}
          colProps={colProps ?? { xs: 12, sm: person?.props_employmentContractType?.hidden ? 6 : 3 }}
          {...personProps}
          {...formInputProps}
        />
      )
    else if (name === "incomeSource")
      return (
        <FormInput
          key={name}
          field="incomeSource"
          label={loc`Source of income`}
          select="incomeSource"
          colProps={colProps ?? { xs: 12, sm: 6 }}
          {...personProps}
          {...formInputProps}
        />
      )
    else if (name === "incomeSalary")
      return (
        <FormInput
          key={name}
          field="incomeSalary"
          label={loc`Salary`}
          type="currency"
          colProps={colProps ?? { xs: 12, sm: 6 }}
          {...personProps}
          {...formInputProps}
        />
      )
    else if (name === "incomeHousing")
      return (
        <FormInput
          key={name}
          field="incomeHousing"
          label={loc`Housing income`}
          type="currency"
          //TODO add currency props
          colProps={colProps ?? { xs: 12, sm: 6 }}
          {...personProps}
          {...formInputProps}
        />
      )
    else if (name === "incomeAllocation")
      return (
        <FormInput
          key={name}
          field="incomeAllocation"
          label={loc`Allocation received`}
          type="currency"
          //TODO add currency props
          colProps={colProps ?? { xs: 12, sm: 6 }}
          {...personProps}
          {...formInputProps}
        />
      )
    else if (name === "incomeOther")
      return (
        <FormInput
          key={name}
          field="incomeOther"
          label={loc`Other income`}
          type="currency"
          //TODO add currency props
          colProps={colProps ?? { xs: 12, sm: 6 }}
          {...personProps}
          {...formInputProps}
        />
      )
    else if (name === "incomeTotal")
      return (
        <FormInput
          key={name}
          field="incomeTotal"
          label={loc`Total income`}
          type="currency"
          //TODO add currency props
          colProps={colProps ?? { xs: 12, sm: 6 }}
          {...personProps}
          {...formInputProps}
        />
      )
    else if (name === "expenditureHousingMonthlyRental")
      return (
        <FormInput
          key={name}
          field="expenditureHousingMonthlyRental"
          label={loc`Rental`}
          type="currency"
          //TODO add currency props
          colProps={colProps ?? { xs: 12, sm: 6 }}
          {...personProps}
          {...formInputProps}
        />
      )
    else if (name === "expenditureHousingMortgageRepayment")
      return (
        <FormInput
          key={name}
          field="expenditureHousingMortgageRepayment"
          label={loc`Mortgage repayment`}
          type="currency"
          //TODO add currency props
          colProps={colProps ?? { xs: 12, sm: 6 }}
          {...personProps}
          {...formInputProps}
        />
      )
    else if (name === "expenditureOtherLoanRepayment")
      return (
        <FormInput
          key={name}
          field="expenditureOtherLoanRepayment"
          label={loc`Other loans`}
          type="currency"
          //TODO add currency props
          colProps={colProps ?? { xs: 12, sm: 6 }}
          {...personProps}
          {...formInputProps}
        />
      )
    else if (name === "expenditureAlimony")
      return (
        <FormInput
          key={name}
          field="expenditureAlimony"
          label={loc`Child maintenance`}
          type="currency"
          //TODO add currency props
          colProps={colProps ?? { xs: 12, sm: 6 }}
          {...personProps}
          {...formInputProps}
        />
      )
    else if (name === "expenditureTaxes")
      return (
        <FormInput
          key={name}
          field={name}
          label={loc`Taxes (monthly)`}
          type="currency"
          //TODO add currency props
          colProps={colProps ?? { xs: 12, sm: 6 }}
          {...personProps}
          {...formInputProps}
        />
      )
    else if (name === "expenditureTotal")
      return (
        <FormInput
          key={name}
          field={name}
          label={loc`Total expenditure`}
          type="currency"
          //TODO add currency props
          colProps={colProps ?? { xs: 12, sm: 6 }}
          {...personProps}
          {...formInputProps}
        />
      )
    else if (name === "name")
      return (
        showName && (
          <FormInput key={name} mandatory field="name" colProps={colProps ?? { xs: 12, sm: 6, md: 3 }} {...personProps} {...formInputProps} />
        )
      )
    else if (name === "commercialName")
      return <FormInput key={name} field={name} colProps={colProps ?? { xs: 12, sm: 6, md: 3 }} {...personProps} {...formInputProps} />
    else if (name === "legalForm")
      return (
        <FormInput key={name} field={name} select="legalForm" colProps={colProps ?? { xs: 12, sm: 6, md: 3 }} {...personProps} {...formInputProps} />
      )
    else if (name === "currency")
      return (
        showCurrency && (
          <FormInput
            key={name}
            colProps={colProps ?? { xs: 12, sm: 3 }}
            field="currency"
            select={this.props.currencies}
            {...personProps}
            {...formInputProps}
          />
        )
      )
    else if (name === "creationDate")
      return (
        <FormInput
          key={name}
          field={name}
          label={loc`Date of creation`}
          type="date"
          colProps={colProps ?? { xs: 12, sm: 6, md: 3 }}
          {...companyProps}
        />
      )
    else if (name === "registrationDate")
      return (
        <FormInput
          key={name}
          field={name}
          label={loc`Date of registration`}
          type="date"
          colProps={colProps ?? { xs: 12, sm: 6, md: 3 }}
          {...companyProps}
        />
      )
    else if (name === "shareCapital")
      return <FormInput key={name} field="shareCapital" type="currency" colProps={colProps ?? { xs: 12, sm: 6, md: 3 }} {...companyProps} />
    else if (name === "registrationCountry")
      return <FormInput key={name} field="registrationCountry" select="country" colProps={colProps ?? { xs: 12, sm: 6, md: 3 }} {...companyProps} />
    else if (name === "registrationPlace")
      return <FormInput key={name} field="registrationPlace" colProps={colProps ?? { xs: 12, sm: 6, md: 3 }} {...companyProps} />
    else if (name === "registrationInfo")
      return <FormInput key={name} field="registrationInfo" colProps={colProps ?? { xs: 12, sm: 6 }} {...companyProps} />
    else if (name === "vatScheme")
      return (
        <FormInput
          key={name}
          label={loc`VAT scheme`}
          field="vatScheme"
          select="vatScheme"
          colProps={colProps ?? { xs: 12, sm: 6, md: 3 }}
          {...companyProps}
        />
      )
    else if (name === "activityCode")
      return (
        <FormInput
          key={name}
          field="activityCode"
          select="activityCode"
          colProps={colProps ?? { xs: 12, sm: 6, md: 3 }}
          {...personProps}
          modelPath={modelPath}
        />
      )
    else if (name === "naceCode")
      return (
        <FormInput
          key={name}
          label={loc`NACE code`}
          field="naceCode"
          select="naceCode"
          colProps={colProps ?? { xs: 12, sm: 6, md: 3 }}
          {...companyProps}
        />
      )
    else if (name === "sectorCode")
      return <FormInput key={name} field="sectorCode" select="sectorCode" colProps={colProps ?? { xs: 12, sm: 6, md: 3 }} {...companyProps} />
    else if (name === "insuranceNumber")
      return <FormInput key={name} field="insuranceNumber" colProps={colProps ?? { xs: 12, sm: 6, md: 3 }} {...companyProps} />
    else if (name === "legalRepresentative")
      return <FormInput key={name} field="legalRepresentative" colProps={colProps ?? { xs: 12, sm: 6, md: 3 }} {...companyProps} />
    else if (name === "administrativeStatus")
      return <FormInput key={name} field={name} colProps={colProps ?? { xs: 12, sm: 6, md: 3 }} select="administrativeStatus" {...companyProps} />
    else if (name === "yearOfReference")
      return <FormInput key={name} field={name} colProps={colProps ?? { xs: 12, sm: 6, md: 3 }} {...companyProps} />
    else if (name === "companySize")
      return <FormInput key={name} field="companySize" select="companySize" colProps={colProps ?? { xs: 12, sm: 6, md: 3 }} {...companyProps} />
    else if (name === "nbOfEmployees")
      return <FormInput key={name} field={name} type="number" colProps={colProps ?? { xs: 12, sm: 6, md: 3 }} {...companyProps} />
    else if (name === "accountingStandards")
      return <FormInput key={name} field={name} select="accountingStandards" colProps={colProps ?? { xs: 12, sm: 6, md: 3 }} {...companyProps} />
    else if (name === "totalBalancesheet")
      return <FormInput key={name} field={name} type="currency" colProps={colProps ?? { xs: 12, sm: 6, md: 3 }} {...companyProps} />
    else if (name === "totalTurnover")
      return (
        <FormInput
          key={name}
          field={name}
          type="currency"
          //TODO add currency props
          colProps={colProps ?? { xs: 12, sm: 6, md: 3 }}
          {...companyProps}
        />
      )
    else if (name === "legalProceedingsStatus")
      return <FormInput key={name} field={name} select="legalStatusCode" colProps={colProps ?? { xs: 12, sm: 6, md: 3 }} {...companyProps} />
    else if (name === "dateLegalProceeding")
      return <FormInput key={name} field={name} type="date" colProps={colProps ?? { xs: 12, sm: 6, md: 3 }} {...companyProps} />
    else if (name === "website") return <FormInput key={name} field={name} colProps={colProps ?? { xs: 12, sm: 6 }} {...webProps} />
    else if (name === "linkedIn") return <FormInput key={name} field={name} label="LinkedIn" colProps={colProps ?? { xs: 12, sm: 6 }} {...webProps} />
    else if (name === "facebook") return <FormInput key={name} field={name} colProps={colProps ?? { xs: 12, sm: 6 }} {...webProps} />
    else if (name === "instagram") return <FormInput key={name} field={name} colProps={colProps ?? { xs: 12, sm: 6 }} {...webProps} />
    else if (name) return <FormInputExtended key={name} field={name} colProps={colProps ?? { xs: 12 }} {...personProps} {...formInputProps} />
  }
  render() {
    const {
      person = {},
      tabPerson,
      detailed,
      readOnly,
      layout = {},
      modelPath,
      tabPersonModelPath,
      usePersonLayout,
      useProspectLayout,
      handleSetPersonState,
      handleSetTabPersonState,
      rows,
      isProspect,
    } = this.props

    const layoutCards =
      usePersonLayout || useProspectLayout
        ? layout.cards
            ?.filter(card => !card.props?.hidden && card.card !== "Person")
            .map((card, index) => {
              const { props = {} } = card
              const { colProps, useParentEntityPerson } = props
              const { xs = 12, sm, md, lg } = colProps || {}
              props.noCard = true
              return (
                <Col {...{ xs, sm, md, lg }} key={index}>
                  <LayoutCard
                    key={index}
                    card={card}
                    serverRoute={`/api/person/${isProspect ? "prospects" : "persons"}`}
                    readOnly={readOnly}
                    onSetState={useParentEntityPerson ? handleSetTabPersonState : handleSetPersonState}
                    {...(props || {})}
                    entity={useParentEntityPerson ? tabPerson : person}
                    noCard
                    modelPath={useParentEntityPerson ? tabPersonModelPath : modelPath}
                  />
                </Col>
              )
            })
        : []

    return (
      <div className="person-global">
        {generateRows({ getField: this.getField, rows, entity: person, handleSetState: handleSetPersonState, modelPath, readOnly })}

        {detailed && layoutCards?.length > 0 && <Row>{layoutCards}</Row>}
      </div>
    )
  }
}
