import { isEqual, replaceVariables } from "basikon-common-utils"
import get from "lodash.get"
import React from "react"
import { Col, ControlLabel, FormControl, FormGroup, Modal, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import Select from "react-select"

import { countSearchParams } from "@/_components/AdvancedSearch"
import ButtonWithTooltip from "@/_components/ButtonWithTooltip"
import CustomButton from "@/_components/CustomButton"
import FormInput, { getSelectComponents } from "@/_components/FormInput"
import FormInputExtended from "@/_components/FormInputExtended"
import PanelOuter from "@/_components/PanelOuter"
import PersonLink from "@/_components/PersonLink"
import Table from "@/_components/Table"

import { filterData } from "@/_services/boardUtils"
import { getEntityDefaultConfig, getEntitySearchFields } from "@/_services/entity"
import { getList } from "@/_services/lists"
import { loc } from "@/_services/localization"
import { addOops } from "@/_services/notification"
import { getIsAdmin } from "@/_services/userConfiguration"
import { applyClasses, getEntities, getEntity, labelFromName, searchEntities, toQueryParams } from "@/_services/utils"

/**
 * @prop {String}   entityName                      Name of the entity (camelCase + starts with a capital letter)
 * @prop {[{}]}     query                           Query params object to be applied when fetching the entities
 * @prop {[{}]}     columns                         Array of columns to override standard entity search columns
 * @prop {Boolean}  advancedSearch.collapse         Collapse advanced search panel if true                      Default to undefined.
 * @prop {Boolean}  advancedSearch.hidden           Hide advanced search panel if true                          Default to false.
 * @prop {[{}]}     advancedSearch.fields           Array of fields to override standard entity search fields
 * @prop {[{}]}     advancedSearch.additionalFields Array of additional fields to be added after the standard entity search fields
 * @prop {{}}       advancedSearch.props_{field}    Object to apply field props on {field}
 */
class EntitySearchModal extends React.Component {
  constructor(props) {
    super(props)
    const { params = {}, advancedSearch, columns } = props
    const entityName = props.entityName || props.entity // Remove support for "entity" prop after migrating all scripts

    let collapse = true
    if (advancedSearch?.collapse !== undefined) collapse = advancedSearch.collapse

    let entityColumns
    if (columns) {
      entityColumns = Array.isArray(columns) ? columns : typeof columns === "string" ? columns.split(",") : undefined
      if (Array.isArray(entityColumns)) entityColumns = entityColumns.filter(col => col).map(col => (typeof col === "string" ? { name: col } : col))
    }
    if (!entityColumns) {
      const { columns } = getEntityDefaultConfig(entityName)
      entityColumns = columns
    }

    this.state = {
      collapse,
      entityName,
      entities: [],
      selectedEntities: props.multiple ? new Set(JSON.parse(JSON.stringify([...(props.selectedEntities || [])]))) : undefined,
      form: { ...params },
      isLoading: false,
      entityColumns,
      // query can contain non-filters also like include, projection, etc..
      query: { ...(props.filters || {}), ...(props.query || {}) }, // TODO: Remove support for "filters" prop after migrating all scripts
    }
  }

  componentDidMount() {
    this.getEntities()

    const searchFields = this.getSearchFields()

    searchFields
      .flat()
      .map(field => field.select)
      .filter(list => list && typeof list === "string")
      .forEach(list => this.getList(list))
  }

  componentDidUpdate(prevProps) {
    const prevQuery = { ...(prevProps?.filters || {}), ...(prevProps?.query || {}) } // TODO: Remove support for "filters" prop after migrating all scripts
    const query = { ...(this.props.filters || {}), ...(this.props.query || {}) } // TODO: Remove support for "filters" prop after migrating all scripts

    if (!isEqual(prevQuery, query)) this.setState({ query })
  }

  getList = list => {
    const result = getList(list, result => this.setState({ [list]: result.values }))
    this.setState({ [list]: result.values })
  }

  getEntities = async () => {
    const { entityName, query, entityColumns } = this.state
    const { customGetEntities } = this.props

    this.setState({ isLoading: true })

    const params = { ...(query || {}) }
    if (entityColumns.find(it => it.name === "organization.name" && !it.hidden) && !params.include) params.include = "organizationName"

    try {
      const entities = customGetEntities ? await customGetEntities(params) : await getEntities(entityName, params)
      this.setState({ entities, isLoading: false })
    } catch (error) {
      addOops(error)
      this.setState({ isLoading: false })
    }
  }

  searchEntities = async () => {
    const { form = {}, entityColumns, entityName, query } = this.state
    const { customSearch } = this.props

    const params = { ...(query || {}), ...form, search: undefined }
    if (entityColumns.find(it => it.name === "organization.name" && !it.hidden) && !params.include) params.include = "organizationName"

    const searchFields = this.getSearchFields().flat()

    const queryParams = toQueryParams(params)
    for (const key in queryParams) {
      if (queryParams[key] !== undefined && queryParams[key] !== null) {
        const searchField = searchFields.find(field => field.field === key)
        if (searchField?.regex) queryParams[key] = `/${queryParams[key]}/i`
      }
    }

    this.setState({ isLoading: true })

    try {
      const entities = customSearch ? await customSearch(queryParams) : await getEntities(entityName, queryParams)
      this.setState({ entities, isLoading: false, collapse: true })
    } catch (error) {
      addOops(error)
      this.setState({ isLoading: false, collapse: true })
    }
  }

  quickSearchEntities = async () => {
    const { form, entityName, query, search = "" } = this.state
    const { customQuickSearch } = this.props

    this.setState({ isLoading: true })

    try {
      const queryParams = toQueryParams({ ...(query || {}), ...form })
      const entities = customQuickSearch ? await customQuickSearch(search, queryParams) : await searchEntities(entityName, search, queryParams)
      this.setState({ entities, isLoading: false, collapse: true })
    } catch (error) {
      addOops(error)
      this.setState({ isLoading: false, collapse: true })
    }
  }

  handleClose = async selectedEntity => {
    const { entityName, selectedEntities } = this.state
    const { include, onClose, multiple } = this.props

    let selectedData
    if (selectedEntity) {
      const registration = selectedEntity.registration?.props?.children
      selectedData = registration ? await getEntity(entityName, registration, { include }) : selectedEntity
    } else if (multiple) {
      selectedData = await Promise.all(Array.from(selectedEntities).map(registration => getEntity(entityName, registration, { include })))
    }

    if (onClose) onClose(selectedData)
  }

  handleSearchKeyDown = async event => {
    if (event.key === "Enter") {
      const { search = "" } = this.state

      if (search.trim()) this.quickSearchEntities()
      else this.getEntities()
    }
  }

  handleSetFormState = patch => {
    const { form = {} } = this.state
    this.setState({ form: { ...form, ...patch } })
  }

  resetSearchFields = () => {
    const { onSearchFieldsReset } = this.props
    if (onSearchFieldsReset) onSearchFieldsReset()
    this.setState({ form: {} })
  }

  handleMultipleSelection = entity => {
    const { selectedEntities } = this.state
    if (selectedEntities.has(entity.id)) {
      selectedEntities.delete(entity.id)
    } else {
      selectedEntities.add(entity.id)
    }
    this.setState({ selectedEntities })
  }

  getSearchFields = () => {
    const { entityName } = this.state
    const { advancedSearch } = this.props

    // Custom/Additional search fields
    let searchFields = advancedSearch?.fields || getEntitySearchFields(entityName)
    if (Array.isArray(advancedSearch?.additionalFields)) searchFields = [...searchFields, ...advancedSearch.additionalFields]

    return searchFields
  }

  renderAdvancedSearch() {
    const { form = {}, collapse, query } = this.state
    const { advancedSearch } = this.props

    const formAndFilters = { ...query, ...form }

    // Apply field props
    for (const key of Object.keys(advancedSearch || {}).filter(key => key?.startsWith("props_"))) {
      formAndFilters[key] = { ...(formAndFilters[key] || {}), ...(advancedSearch[key] || {}) }
    }

    // Custom/Additional search fields
    const searchFields = this.getSearchFields().map((fieldsArray, rowIndex) => {
      const fieldCols = (fieldsArray || [])
        .map((formInputProps, index) => {
          let { col, colProps, legend, field, hidden, ...props } = formInputProps
          colProps = colProps || col || { xs: 12, sm: 6, lg: 4 } // TODO: Remove "col" when all scripts are migrated

          if (hidden === true) return null
          if (legend) {
            return (
              <Col xs={12} key={index}>
                <legend className="font-2 mt-2">{loc(legend)}</legend>
              </Col>
            )
          }

          const disabled =
            formAndFilters?.[`props_${field}`]?.disabled !== undefined ? formAndFilters[`props_${field}`].disabled : query?.[field] && !getIsAdmin()

          // multiple
          if (formInputProps.multiple || formInputProps.type === "multiple") {
            if (!formInputProps.label) formInputProps.label = labelFromName(field)
            if (typeof formInputProps.label === "string") formInputProps.label = loc(formInputProps.label)

            const options =
              typeof formInputProps.select === "string"
                ? this.state[formInputProps.select] || []
                : Array.isArray(formInputProps.select)
                ? formInputProps.select
                : []

            const values = Array.isArray(formAndFilters[field]) ? formAndFilters[field] : (formAndFilters[field] || "").split(",")
            const selectedValues = values
              .filter(v => v)
              .map(code => {
                return options.find(cs => cs.value === code) || { value: code, label: `${code} (???)` }
              })

            return (
              <Col key={index} {...colProps}>
                <FormGroup>
                  <ControlLabel>{formInputProps.label}</ControlLabel>
                  <Select
                    className="select-group"
                    classNamePrefix="select"
                    components={getSelectComponents({ isClearable: true })}
                    closeOnSelect={false}
                    isMulti={true}
                    value={selectedValues}
                    options={options}
                    onChange={values => this.handleSetFormState({ [field]: values?.map(v => v.value).join(",") })}
                    isDisabled={disabled}
                    tabSelectsValue={false}
                  />
                </FormGroup>
              </Col>
            )
          } else {
            return (
              <FormInputExtended
                {...{ field, colProps, disabled, ...props }}
                key={index}
                obj={formAndFilters}
                onEnter={this.searchEntities}
                onSetState={this.handleSetFormState}
              />
            )
          }
        })
        .filter(field => field)

      return <Row key={rowIndex}>{fieldCols}</Row>
    })

    return (
      <Col xs={12}>
        <PanelOuter expanded={collapse !== true} onToggle={() => this.setState({ collapse: !collapse })}>
          <legend className="c-pointer mt-theme" onClick={() => this.setState({ collapse: !collapse })}>{loc`Advanced search`}</legend>
          {searchFields}

          <Row>
            <Col xs={12} className="mb-theme text-right">
              <CustomButton bsStyle="danger" bsSize="sm" className="mr-10px" fill onClick={this.resetSearchFields}>
                {loc`Reset`}
              </CustomButton>
              <CustomButton bsStyle="primary" bsSize="sm" fill onClick={this.searchEntities}>
                {loc`Search`}
              </CustomButton>
            </Col>
          </Row>
        </PanelOuter>
      </Col>
    )
  }

  render() {
    const { form = {}, entities = [], search = "", collapse, selectedEntities, entityColumns, entityName, query } = this.state
    const { title, multiple, onClose, advancedSearch, noQuickSearch, showAdd } = this.props

    const isAdvancedSearchHidden = advancedSearch?.hidden === true
    const isLoading = this.state.isLoading || this.props.isLoading

    const formAndFilters = { ...(query || {}), ...form }
    const searchFieldsNb = countSearchParams(formAndFilters, entityName)

    const columns = entityColumns.filter(col => !col.hidden).map(col => ({ title: col.title, name: col.name }))
    const data = entities.map(entity => {
      const row = { id: entity.registration, _data: entity }

      entityColumns.forEach(col => {
        const { name, field = name, link, currencyPath, findIn, findKey, findValue, foundKey, ...props } = col

        if (link) {
          if (findIn && findKey && findValue && foundKey) {
            const found = entity[findIn]?.find(it => it[findKey] === findValue)
            const foundValue = found?.[foundKey]
            if (found && foundValue) {
              row[name] = (
                <Link to={replaceVariables(link, found)} target="_blank">
                  {get(found, field)}
                </Link>
              )
            }
          } else if (typeof link === "string" && link.includes("/person/")) {
            row[name] = <PersonLink registration={get(entity, field)} target="_blank" />
          } else if (typeof link === "string") {
            row[name] = (
              <Link to={replaceVariables(link, entity)} target="_blank">
                {get(entity, field)}
              </Link>
            )
          }
        } else {
          row[name] = (
            <FormInput
              inArray
              readOnly
              obj={entity}
              field={field}
              currency={get(entity, currencyPath)}
              {...props}
              openLinkInNewTab={props.openLinkInNewTab ?? !!props.linkTo}
            />
          )
        }
      })

      return row
    })

    return (
      <Modal show={true} onHide={() => !isLoading && this.handleClose()} bsSize="large" backdrop="static" className="entity-search-modal">
        <Modal.Header closeButton>
          <Modal.Title className="inline-flex align-items-center">
            {loc(title || `Search ${entityName?.toLowerCase()}`)}
            {isLoading && <i className="ml-10px icn-circle-notch icn-spin icn-sm text-primary" />}
          </Modal.Title>
          {showAdd && (
            <div className="float-right">
              {" "}
              <CustomButton bsSize="sm" bsStyle="info" fill onClick={() => onClose("add")}>
                {loc(showAdd.label || `Add`)}
              </CustomButton>
            </div>
          )}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col
              xs={isAdvancedSearchHidden ? 12 : 10}
              sm={isAdvancedSearchHidden ? 12 : 11}
              className={applyClasses({ "pdb-4": isAdvancedSearchHidden })}
            >
              <FormControl
                type="text"
                value={search}
                bsClass="form-control"
                onKeyDown={!noQuickSearch && this.handleSearchKeyDown}
                onChange={e => this.setState({ search: e.target.value })}
                placeholder={noQuickSearch ? loc`Type to filter` : loc`Type to filter or press enter to search in database`}
              />
            </Col>
            {!isAdvancedSearchHidden && (
              <Col xs={2} sm={1} className="flex">
                <ButtonWithTooltip
                  bsStyle="default"
                  className="icn-search-filter"
                  btnClassName="flex-center"
                  data-test="advanced-search-btn"
                  onClick={() => this.setState({ collapse: !collapse })}
                  tooltip={loc("Toggle search filters")}
                  statusIndicator={searchFieldsNb ? "info" : ""}
                />
              </Col>
            )}
            {!isAdvancedSearchHidden && this.renderAdvancedSearch()}
          </Row>

          <Row className="mt-10px">
            <Col xs={12}>
              <Table
                overflowX
                data-test="search-entity-modal-table"
                columns={columns}
                data={filterData(columns, data, search)}
                pageSize={10}
                pageInUrl={false}
                selectedEntities={selectedEntities}
                onRowClick={dataRow => !isLoading && (multiple ? this.handleMultipleSelection(dataRow) : this.handleClose(dataRow._data))}
              />
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <CustomButton bsSize="sm" disabled={isLoading} onClick={() => onClose()}>{loc`Cancel`}</CustomButton>

          {/* no selection is a valid selection, so no check on it to disable / hide this button */}
          {multiple && (
            <CustomButton disabled={isLoading} bsStyle="primary" bsSize="sm" fill onClick={() => this.handleClose()}>{loc`Validate`}</CustomButton>
          )}
        </Modal.Footer>
      </Modal>
    )
  }
}

export default EntitySearchModal
