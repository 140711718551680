import axios from "axios"
import { formatPercentage, getCurrencySymbol } from "basikon-common-utils"
import * as debounce from "lodash.debounce"
import React, { Suspense } from "react"

import ButtonWithTooltip from "@/_components/ButtonWithTooltip"
import Card from "@/_components/Card"
import CustomButton from "@/_components/CustomButton"
import EntitySearchModal from "@/_components/EntitySearchModal"
import FormInput from "@/_components/FormInput"
import Table, { filterColumns } from "@/_components/Table"

import { computeAsset, findCatalogItemCategories, findCatalogLeafItems } from "@/_services/assetUtils"
import { runScriptAndCacheResponse } from "@/_services/cache"
import consoleService from "@/_services/console"
import { getTaxes } from "@/_services/coreUtils"
import { search as searchLectura } from "@/_services/lecturaUtils"
import { getList, getValues } from "@/_services/lists"
import { getLocale, loc } from "@/_services/localization"
import { addOops } from "@/_services/notification"
import { getConfigAtPath } from "@/_services/userConfiguration"
import { DEFAULT_DEBOUNCE } from "@/_services/utils"

const ContractAssetModal = React.lazy(() => import("@/financing/ContractAssetModal"))

class EntityAssetsComponent extends React.Component {
  state = {
    taxes: [],
    catalog: {},
    selectedAssetIndex: null,
    showSearchAssetsModal: false,
    locale: getLocale(),
  }

  componentDidMount() {
    this.getCatalog()
    getTaxes().then(taxes => this.setState({ taxes }))

    getList("assetType", () => this.setState({ loaded: true }))
  }

  componentDidUpdate(prevProps) {
    const { financingProduct = {} } = this.props
    const { assetCatalog } = financingProduct
    const prevAssetsCatalog = prevProps?.financingProduct?.assetCatalog

    if (assetCatalog && assetCatalog !== prevAssetsCatalog) this.getCatalog()
  }

  handleSetEntityState = patch => {
    const { handleSetEntityState, execComputations = true } = this.props
    handleSetEntityState(patch, execComputations)
  }

  getCatalog = async () => {
    const { financingProduct = {}, catalogName } = this.props
    const { assetCatalog } = financingProduct
    const assetCatalogName = assetCatalog || catalogName

    if (assetCatalogName && !["LECTURA", "EMPREKIS", "AUTOVISTA", "AUTOBIZ", "CARTELL", "ACB"].includes(assetCatalogName)) {
      try {
        const catalog = await runScriptAndCacheResponse(assetCatalogName)

        // load brand level of subcatalog
        const brandsToLoad = []
        if (catalog && catalog.items) {
          const types = catalog.items
          for (const type of types) {
            if (type && type.items) {
              for (const brand of type.items) {
                if (brand && brand.items && brand.items.subCatalogName) brandsToLoad.push(brand)
              }
            }
          }
        }
        const results = await Promise.all(
          brandsToLoad.map(async it => {
            try {
              return await runScriptAndCacheResponse(it.items.subCatalogName)
            } catch {} // eslint-disable-line
          }),
        )

        if (consoleService.showContractAssetsSubcatalogs) console.log("--- loading subcatalogs", brandsToLoad, results)

        for (let i = 0; i < brandsToLoad.length; i++) {
          brandsToLoad[i].items = results[i] && results[i].items
        }

        this.setState({ catalog })
      } catch (error) {
        addOops(error)
      }
    }
  }

  handleAddAsset = event => {
    event.preventDefault()
    const { entity, isPartExchange } = this.props

    const currency = entity.currency || (entity.persons && entity.persons[0].invoicing?.principalCurrency)
    const assets = [...(entity.assets || [])]

    assets.push({
      isFinanced: !isPartExchange,
      isPartExchange,
      asset: { quantity: 1, currency, supplierCurrency: currency },
    })

    this.handleSetEntityState({ assets })
  }

  handleSearchAssetModalClose = async asset => {
    if (asset) {
      const { isPartExchange, entity } = this.props
      const { selectedAssetIndex } = this.state

      const assets = [...(entity.assets || [])]
      asset = (await axios.get(`/api/asset/assets/${asset.registration}`)).data
      asset.quantity = 1
      asset.unitPrice = asset.priceExclTax

      assets[selectedAssetIndex] = {
        ...assets[selectedAssetIndex],
        assetRegistration: asset.registration,
        asset,
        isPartExchange,
        isFinanced: !isPartExchange,
      }
      this.handleSetEntityState({ assets })
    }
    this.setState({ selectedAssetIndex: null, showSearchAssetsModal: null })
  }

  handleDeleteAsset = index => {
    const { entity = {} } = this.props
    const { persons = [], assets = [] } = entity

    const patch = {}

    // Delete asset SUPPLIER from entity if exists
    const { asset = {} } = assets[index] || {}
    const { supplierRegistration } = asset || {}
    if (supplierRegistration) {
      const supplierIndex = persons.findIndex(p => p.role === "SUPPLIER" && p.personRegistration === supplierRegistration)
      if (supplierIndex !== -1) {
        persons.splice(supplierIndex, 1)
        patch.persons = persons
      }
    }

    // Delete asset
    assets.splice(index, 1)
    patch.assets = assets

    this.handleSetEntityState(patch)
  }

  handleDuplicateAsset = i => {
    const assets = [...(this.props.entity.assets || [])]
    const assetItem = { ...assets[i] }
    assetItem.asset = { ...assetItem.asset }
    assets.splice(i, 0, assetItem)
    this.handleSetEntityState({ assets })
  }

  handleSetContractAssetState = (index, patch) => {
    const { entity = {} } = this.props

    if (patch) {
      const { persons = [], assets = [] } = entity

      assets[index] = {
        ...(assets[index] || {}),
        ...patch,
      }

      patch = { assets }

      // Add asset supplier to entity
      const { asset = {} } = assets[index]
      const { supplierRegistration } = asset
      if (supplierRegistration) {
        const hasSupplier = persons.find(p => p.role === "SUPPLIER" && p.personRegistration === supplierRegistration)
        if (!hasSupplier) {
          persons.push({ role: "SUPPLIER", personRegistration: supplierRegistration })
          patch.persons = persons
        }
      }

      this.handleSetEntityState(patch)
    }
  }

  handleAssetModalClose = (entityAssetIndex, entityAsset) => {
    if (entityAsset) {
      this.handleSetContractAssetState(entityAssetIndex, entityAsset)
    }
    this.setState({ showAssetModal: null })
  }

  handleSetAssetAutoState = (index, patch) => {
    const { entity = {} } = this.props
    const { assets = [] } = entity

    patch = {
      auto: {
        ...(assets[index]?.asset?.auto || {}),
        ...patch,
      },
    }

    this.handleSetAssetState(index, patch)
  }

  handleSetAssetStateSync = async (index, patch) => {
    const { catalog = {}, taxes = [] } = this.state
    const { entity = {} } = this.props

    if (patch) {
      const assets = [...(entity.assets || [])]

      const entityAssetPatch = {}

      if ("name" in patch) {
        const catalogItem = applyCatalogItem(entityAssetPatch, this.props)
        if (catalogItem) assets[index] = {}
      } else if (assets[index] && assets[index].isFinanced === undefined) {
        entityAssetPatch.isFinanced = true
      }

      if ("type" in patch) {
        applyCatalogItem(entityAssetPatch, this.props)

        assets[index] = assets[index] || {}
        assets[index].asset = assets[index].asset || {}
        assets[index].asset.categories = assets[index].asset.categories || []

        const typeCategoryIndex = assets[index].asset.categories.findIndex(c => c.name && c.name.toUpperCase() === "TYPE")

        if (typeCategoryIndex !== -1) assets[index].asset.categories[typeCategoryIndex].code = patch.type
        else assets[index].asset.categories.push({ name: "TYPE", code: patch.type })
      }

      const asset = (assets[index] || {}).asset || {}
      assets[index] = {
        ...(assets[index] || {}),
        ...entityAssetPatch,
        asset: computeAsset(asset, { ...patch, isChanged: true }, { taxes }),
      }

      patch = { assets }
    }
    this.handleSetEntityState(patch)

    function applyCatalogItem(entityAssetPatch, props) {
      const { allowAssetNameOptions = true } = props
      const hasCatalog = catalog.items && Array.isArray(catalog.items) && catalog.items.length > 0
      if (hasCatalog) {
        const item = findCatalogItem(catalog.items, patch.code || patch.model || patch.name || patch.type)
        if (item) {
          const {
            code,
            name,
            description,
            defaultTax,
            unitPrice,
            sourcePrice,
            taxCode,
            isFinanced = true,
            serviceCode,
            rvCode,
            paramsCatalog,
            ...props
          } = item
          const categories = findCatalogItemCategories(catalog, code)
          if ((name || description) && allowAssetNameOptions) patch.name = name || description
          if (description) patch.description = description
          if (defaultTax) patch.taxCode = defaultTax
          if (taxCode) patch.taxCode = taxCode
          if (unitPrice) patch.unitPrice = unitPrice
          if (sourcePrice) patch.sourcePrice = sourcePrice
          if (!sourcePrice && unitPrice) patch.sourcePrice = unitPrice
          if (code) patch.model = code
          if (serviceCode && serviceCode !== "null") patch.serviceCode = serviceCode
          if (rvCode && rvCode !== "null") patch.rvCode = rvCode
          if (paramsCatalog && paramsCatalog !== "null") patch.paramsCatalog = paramsCatalog
          entityAssetPatch.isFinanced = isFinanced
          patch.categories = categories
          patch = { ...patch, ...props, parent: undefined, parents: undefined, items: undefined }

          const category = categories.find(c => c.name?.toLowerCase() === "category")?.code
          if (!patch.category && category) patch.category = category
          return item
        } else {
          // New asset (outside of catalog)
          entityAssetPatch.isFinanced = true
        }
      }
    }
  }

  handleSetAssetStateDebounced = debounce(this.handleSetAssetStateSync, DEFAULT_DEBOUNCE)

  handleSetAssetState = (index, patch) => {
    this.handleSetAssetStateDebounced(index, patch)
  }

  getCatalogItems = async search => {
    const { financingProduct = {}, autoLoadCatalogItems, catalogName } = this.props
    const {
      catalog: { items = [] },
    } = this.state

    const { assetCatalog } = financingProduct
    if ((assetCatalog || catalogName)?.toUpperCase() === "LECTURA") return await searchLectura(search)

    const itemsFound = findCatalogLeafItems(items, search, autoLoadCatalogItems)
    const itemOptions = itemsFound.map(i => ({ value: i.code, label: i.name }))

    return Promise.resolve(itemOptions)
  }

  handleSetTotalDiscount = patch => {
    if (patch) {
      const { taxes = [] } = this.state
      const { totalDiscount } = patch
      const { entity = {} } = this.props
      const { assets = [] } = entity

      if (assets.length > 0) {
        assets.forEach((asset, index) => {
          assets[index].asset = computeAsset(assets[index].asset, { discount: totalDiscount }, { taxes })
          assets[index].asset.isChanged = true
        })

        this.handleSetEntityState({ assets })
      }
    }
  }

  getField = (column, entityAsset, index, inArray = true, hideAddOn = true) => {
    const { name: fieldName, type, select, badge } = column
    const { catalog = {} } = this.state
    const { entity = {}, financingProduct, catalogName, allowAssetNameOptions = true } = this.props
    let { readOnly } = this.props

    const assetCatalogName = (financingProduct && financingProduct.assetCatalog) || catalogName
    if (readOnly === undefined) readOnly = entity.readOnly
    if (typeof column.readOnly === "boolean") readOnly = column.readOnly

    const asset = entityAsset.asset ? entityAsset.asset : {}
    const handleSetAssetStateIndex = patch => this.handleSetAssetState(index, patch)
    const handleSetAssetAutoStateIndex = patch => this.handleSetAssetAutoState(index, patch)

    switch (fieldName) {
      case "registration": {
        return asset.registration
      }
      case "isPartExchange": {
        return entityAsset.isPartExchange
      }
      case "isFinanced": {
        return entityAsset.isFinanced
      }
      case "name": {
        const hasCatalog =
          (Array.isArray(catalog.items) && catalog.items.length > 0) ||
          ["LECTURA", "EMPREKIS", "AUTOVISTA", "AUTOBIZ", "CARTELL", "ACB"].includes(assetCatalogName?.toUpperCase())

        return (
          <FormInput
            showCopy
            inArray={inArray}
            readOnly={readOnly}
            obj={asset}
            field="name"
            creatable={allowAssetNameOptions && hasCatalog}
            select={allowAssetNameOptions && hasCatalog && this.getCatalogItems}
            onSetState={handleSetAssetStateIndex}
          />
        )
      }
      case "description": {
        return <FormInput inArray={inArray} readOnly={readOnly} obj={asset} field="description" onSetState={handleSetAssetStateIndex} />
      }
      case "type": {
        const { type, categories = [] } = asset
        const categoryType = categories.find(c => c.name && c.name.toUpperCase() === "TYPE")
        const assetType = (categoryType && categoryType.code) || type
        const hasCatalog = Object.keys(catalog).length > 0
        const types = hasCatalog
          ? (findCatalogCategoryItems(catalog, "type") || []).map(t => ({ value: t.code, label: t.name }))
          : getValues("assetType") || []

        return (
          <FormInput
            inArray={inArray}
            readOnly={readOnly}
            obj={asset}
            value={assetType}
            field="type"
            select={types}
            onSetState={handleSetAssetStateIndex}
          />
        )
      }
      case "identificationNumber": {
        return <FormInput inArray={inArray} readOnly={readOnly} obj={asset} field="identificationNumber" onSetState={handleSetAssetStateIndex} />
      }
      case "unitPrice": {
        return (
          <FormInput
            inArray={inArray}
            readOnly={readOnly}
            obj={asset}
            field="unitPrice"
            type="currency"
            currency={entity.currency}
            hideAddOn={hideAddOn}
            onSetState={handleSetAssetStateIndex}
          />
        )
      }
      case "sourcePrice": {
        return (
          <FormInput
            inArray={inArray}
            readOnly={readOnly}
            obj={asset}
            field="sourcePrice"
            type="currency"
            currency={entity.currency}
            hideAddOn={hideAddOn}
            onSetState={handleSetAssetStateIndex}
          />
        )
      }
      case "quantity": {
        return <FormInput obj={asset} type="number" field="quantity" inArray={inArray} readOnly={readOnly} onSetState={handleSetAssetStateIndex} />
      }
      case "discount": {
        return (
          <FormInput
            obj={asset}
            field="discount"
            type="percentage"
            inArray={inArray}
            readOnly={readOnly}
            hideAddOn={hideAddOn}
            onSetState={handleSetAssetStateIndex}
          />
        )
      }
      case "priceExclTax": {
        return (
          <FormInput
            inArray={inArray}
            readOnly={readOnly}
            obj={asset}
            field="priceExclTax"
            type="currency"
            currency={entity.currency}
            hideAddOn={hideAddOn}
            onSetState={handleSetAssetStateIndex}
          />
        )
      }
      case "taxCode": {
        const { taxes = [] } = this.state
        return (
          <FormInput
            obj={asset}
            field="taxCode"
            inArray={inArray}
            readOnly={readOnly}
            select={taxes.map(tax => ({ value: tax.code, label: tax.label || loc(tax.code) }))}
            onSetState={handleSetAssetStateIndex}
          />
        )
      }
      case "priceInclTax": {
        return (
          <FormInput
            inArray={inArray}
            readOnly={readOnly}
            obj={asset}
            field="priceInclTax"
            type="currency"
            currency={entity.currency}
            hideAddOn={hideAddOn}
            onSetState={handleSetAssetStateIndex}
          />
        )
      }
      case "invoiceNumber": {
        return <FormInput obj={asset} inArray={inArray} readOnly={readOnly} field="invoiceNumber" onSetState={handleSetAssetStateIndex} />
      }
      case "model": {
        return (
          <FormInput obj={asset} field="model" inArray={inArray} readOnly={readOnly} hideAddOn={hideAddOn} onSetState={handleSetAssetStateIndex} />
        )
      }
      case "manufacturer": {
        return (
          <FormInput
            obj={asset}
            field="manufacturer"
            inArray={inArray}
            readOnly={readOnly}
            hideAddOn={hideAddOn}
            onSetState={handleSetAssetStateIndex}
          />
        )
      }
      case "category": {
        return (
          <FormInput obj={asset} field="category" inArray={inArray} readOnly={readOnly} hideAddOn={hideAddOn} onSetState={handleSetAssetStateIndex} />
        )
      }
      case "registrationDate": {
        return (
          <FormInput
            type="date"
            inArray={inArray}
            readOnly={readOnly}
            obj={asset?.auto || {}}
            field="registrationDate"
            onSetState={handleSetAssetAutoStateIndex}
          />
        )
      }
      case "licenceNumber": {
        return (
          <FormInput inArray={inArray} readOnly={readOnly} obj={asset?.auto || {}} field="licenceNumber" onSetState={handleSetAssetAutoStateIndex} />
        )
      }
      case "actions": {
        const { showEdit = true, showCopy = true, showAssetsSearch } = this.props

        return {
          className: "text-right",
          content: (
            <>
              {showAssetsSearch && !readOnly && (
                <ButtonWithTooltip
                  className="icn-search icn-xs"
                  onClick={() => this.setState({ showSearchAssetsModal: true, selectedAssetIndex: index })}
                />
              )}
              {showEdit && (
                <ButtonWithTooltip
                  className={`icn-xs icn-${readOnly ? "eye" : "edit"}`}
                  onClick={() => this.setState({ showAssetModal: { entityAssetIndex: index, entityAsset } })}
                  tooltip={readOnly ? "View" : "Edit"}
                />
              )}
              {showCopy && !readOnly && <ButtonWithTooltip className="icn-duplicate icn-xs" onClick={() => this.handleDuplicateAsset(index)} />}
              {!readOnly && <ButtonWithTooltip className="icn-xmark icn-xs" onClick={() => this.handleDeleteAsset(index)} />}
            </>
          ),
        }
      }
      default: {
        // as a fallback we return an asset field - this will allow the support of extensions
        return (
          <FormInput
            obj={asset}
            type={type}
            badge={badge}
            select={select}
            field={fieldName}
            inArray={inArray}
            readOnly={readOnly}
            onSetState={handleSetAssetStateIndex}
          />
        )
      }
    }
  }

  render() {
    const {
      debugInfo,
      entity = {},
      financingProduct,
      title = "Assets",
      titleSideText,
      collapse,
      columns = [],
      selectionModes,
      showAuto,
      showAutoPanel,
      modelPath = "",
      hideTotalPriceExclTax,
      hideTotalDiscount,
      hideTotalPriceInclTax,
      hideTotalUnitPrice,
      hideTotalSourcePrice,
      hideTotalQuantity,
      isPartExchange,
      showAssetsSearch,
      useAssetLayout,
      assetExtensionTitle,
      entityName,
      noCard,
      cardName,
      rows,
    } = this.props
    let { readOnly } = this.props
    const { showAssetModal, showSearchAssetsModal, locale, catalog = {} } = this.state
    if (readOnly === undefined) readOnly = entity.readOnly

    const currencySymbol = getCurrencySymbol(locale, entity.currency, { currencyDisplay: getConfigAtPath("currencyDisplay") })
    const { assets: entityAssets = [], quotations = [] } = entity
    const [quotation = {}] = quotations
    const { assetMarginRate = 0 } = quotation

    const filteredColumns = filterColumns(
      [
        { title: "Registration", name: "registration", linkTo: "/asset/{registration}", hidden: true },
        { title: "Is financed", name: "isFinanced", type: "checkbox", hidden: true },
        { title: "Is part exchange", name: "isPartExchange", type: "checkbox", hidden: true },
        { title: "Name", name: "name" },
        { title: "Description", name: "description", hidden: true },
        { title: "Type", name: "type", hidden: true },
        { title: "Identification number", name: "identificationNumber", hidden: true },
        { title: "Registration date", name: "registrationDate", hidden: true },
        { title: "Licence number", name: "licenceNumber", hidden: true },
        { title: `${loc`Unit price`} ${currencySymbol}`, name: "unitPrice" },
        { title: `${loc`Source price`} ${currencySymbol}`, name: "sourcePrice", hidden: true },
        { title: "Quantity", name: "quantity" },
        { title: `${loc`Discount`} %`, name: "discount", hidden: true },
        { title: `${loc`Total (excl. tax)`} ${currencySymbol}`, name: "priceExclTax" },
        { title: "Tax", name: "taxCode", hidden: true },
        { title: `${loc`Total (incl. tax)`} ${currencySymbol}`, name: "priceInclTax" },
        { title: "Invoice number", name: "invoiceNumber", hidden: true },
        { title: "Manufacturer", name: "manufacturer", hidden: true },
        { title: "Model", name: "model", hidden: true },
        { title: "Category", name: "category", hidden: true },
        { title: "", name: "actions" },
      ],
      columns,
    )

    if (entityAssets.length === 0 && !readOnly) entityAssets.push({ asset: {} })

    const data = entityAssets
      .map((entityAsset, index) => {
        const row = { ...entityAsset, ...entityAsset?.asset }
        filteredColumns.forEach(col => (row[col.name] = this.getField(col, entityAsset, index)))
        return row
      })
      .filter(isPartExchange ? it => it.isPartExchange : it => !it.isPartExchange)

    // Total line
    const hasTotalizableColumn = filteredColumns.find(filteredColumn =>
      ["priceExclTax", "priceInclTax", "sourcePrice", "unitPrice", "quantity"].includes(filteredColumn.name),
    )
    if (entityAssets.length > 1 && hasTotalizableColumn) {
      let totalPriceExclTax = 0
      let totalPriceInclTax = 0
      let totalSourcePrice = 0
      let totalUnitPrice = 0
      let totalQuantity = 0

      entityAssets.forEach(({ asset }) => {
        const { priceExclTax = 0, priceInclTax = 0, sourcePrice = 0, unitPrice = 0, quantity = 1 } = asset || {}

        totalPriceExclTax += priceExclTax
        totalPriceInclTax += priceInclTax
        totalSourcePrice += sourcePrice * quantity
        totalUnitPrice += unitPrice * quantity
        totalQuantity += quantity
      })

      const totalDiscount = totalPriceExclTax && totalUnitPrice ? 1 - totalPriceExclTax / totalUnitPrice : 0

      // Totals
      data.push({
        priceExclTax: {
          className: "font-weight-bold",
          content: (
            <FormInput
              readOnly
              inArray
              hideAddOn
              value={totalPriceExclTax}
              hidden={hideTotalPriceExclTax}
              type="currency"
              currency={entity.currency}
            />
          ),
        },
        priceInclTax: {
          className: "font-weight-bold",
          content: (
            <FormInput
              readOnly
              inArray
              hideAddOn
              value={totalPriceInclTax}
              hidden={hideTotalPriceInclTax}
              type="currency"
              currency={entity.currency}
            />
          ),
        },
        sourcePrice: {
          className: "font-weight-bold",
          content: (
            <FormInput readOnly inArray hideAddOn value={totalSourcePrice} hidden={hideTotalSourcePrice} type="currency" currency={entity.currency} />
          ),
        },
        unitPrice: {
          className: "font-weight-bold",
          content: (
            <FormInput readOnly inArray hideAddOn value={totalUnitPrice} hidden={hideTotalUnitPrice} type="currency" currency={entity.currency} />
          ),
        },
        discount: (
          <FormInput
            inArray
            hideAddOn
            readOnly={readOnly}
            field="totalDiscount"
            value={totalDiscount}
            type="percentage"
            hidden={hideTotalDiscount}
            onSetState={this.handleSetTotalDiscount}
          />
        ),
        quantity: {
          className: "font-weight-bold",
          content: <FormInput inArray hideAddOn readOnly field="totalQuantity" value={totalQuantity} type="number" hidden={hideTotalQuantity} />,
        },
        taxCode: {
          className: "font-weight-bold",
          content: formatPercentage(assetMarginRate, locale),
        },
      })
    }

    return (
      <>
        <Card
          debugInfo={debugInfo}
          noCard={noCard}
          collapse={collapse}
          title={loc(title)}
          titleSideText={titleSideText}
          className="entity-assets-component"
          action={
            !readOnly && (
              <CustomButton bsStyle="primary" bsSize="small" className="inline-flex align-items-center" pullRight fill onClick={this.handleAddAsset}>
                <i className="icn-plus icn-xs mr-5px" />
                {loc`Add line`}
              </CustomButton>
            )
          }
        >
          <Table
            data-test="entity-assets-table"
            columns={filteredColumns}
            data={data}
            pageInUrl={false}
            modelPath={modelPath}
            modelPathNested="asset"
          />
        </Card>

        {showAssetModal && (
          <Suspense fallback={null}>
            <ContractAssetModal
              readOnly={readOnly}
              onClose={this.handleAssetModalClose}
              entityAssetIndex={showAssetModal.entityAssetIndex}
              entityAsset={{ ...showAssetModal.entityAsset, readOnly }}
              entityName={entityName}
              {...{
                showAuto,
                showAutoPanel,
                selectionModes,
                catalog,
                financingProduct,
                showAssetsSearch,
                useAssetLayout,
                cardName,
                isPartExchange,
                assetExtensionTitle,
                rows,
              }}
            />
          </Suspense>
        )}

        {showSearchAssetsModal && <EntitySearchModal entityName="Asset" onClose={this.handleSearchAssetModalClose} />}
      </>
    )
  }
}

export default EntityAssetsComponent

function findCatalogItem(items, code) {
  for (const item of items) {
    if (item.code === code) return item

    if (Array.isArray(item.items)) {
      const found = findCatalogItem(item.items, code)
      if (found) return { ...found, type: item.code }
    }
  }
}

function findCatalogCategoryItems(catalog = {}, category = "") {
  if (catalog.category && catalog.category.toUpperCase() === category.toUpperCase()) {
    return catalog.items
  }
  // TODO: search also other levels
}
