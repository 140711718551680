import React from "react"
import { Col, Modal } from "react-bootstrap"

import CustomButton from "@/_components/CustomButton"
import FormInput from "@/_components/FormInput"
import FormInputExtended from "@/_components/FormInputExtended"

import { getUriAndCacheResponse } from "@/_services/cache"
import { loc } from "@/_services/localization"
import { generateRows } from "@/_services/utils"

class PersonContactModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      contact: props.currentContact || {},
      contactsExtensionFields: [],
    }
  }

  componentDidMount() {
    this.getPersonContactsExtensionFields()
  }

  getPersonContactsExtensionFields = async () => {
    const { schema } = await getUriAndCacheResponse("/api/person/persons-schema")

    const contactsExtensionFields = []
    for (let fieldName in schema.contacts[0]) {
      // TODO: Flag extension fields in "{serverRoute}-schema" API to differentiate and avoid hard-coding standard fields
      const standardFields = [
        "isDefault",
        "title",
        "position",
        "firstName",
        "lastName",
        "secondName",
        "phone",
        "mobile",
        "email",
        "linkedIn",
        "facebook",
        "instagram",
        "personRegistration",
      ]
      if (standardFields.includes(fieldName)) continue

      const { formInputProps = {}, colProps } = schema.contacts[0][fieldName]

      contactsExtensionFields.push({ field: fieldName, ...formInputProps, colProps: colProps || formInputProps.colProps || { xs: 12 } })
    }

    this.setState({ contactsExtensionFields })
  }

  handleContactState = patch => {
    const { contact } = this.state
    if (patch) {
      this.setState({ contact: { ...contact, ...patch } })
    }
  }

  saveContact = () => {
    const { contact } = this.state
    const { closeModal, onSave } = this.props
    closeModal()
    onSave(contact)
  }

  getField = col => {
    const { name, colProps, formInputProps, label, hidden } = col
    if (hidden) return
    const { contact, contactsExtensionFields } = this.state
    const { readOnly, modelPath = "" } = this.props

    if (name === "title") {
      return (
        <FormInput
          key={name}
          readOnly={readOnly}
          obj={contact}
          field={name}
          colProps={colProps ?? { xs: 12, sm: 6 }}
          label={label}
          select="personTitle"
          onSetState={this.handleContactState}
          modelPath={modelPath}
          {...formInputProps}
        />
      )
    }

    if (["firstName", "lastName", "secondName"].includes(name)) {
      return (
        <FormInput
          key={name}
          readOnly={readOnly}
          obj={contact}
          field={name}
          colProps={colProps ?? { xs: 12, sm: 6 }}
          label={label}
          onSetState={this.handleContactState}
          modelPath={modelPath}
          {...formInputProps}
        />
      )
    }

    if (name === "position") {
      return (
        <FormInput
          key={name}
          readOnly={readOnly}
          obj={contact}
          field={name}
          colProps={colProps ?? { xs: 12 }}
          label={label}
          select="contactPosition"
          mandatory
          onSetState={this.handleContactState}
          modelPath={modelPath}
          {...formInputProps}
        />
      )
    }

    if (["phone", "mobile"].includes(name)) {
      return (
        <FormInput
          key={name}
          readOnly={readOnly}
          obj={contact}
          field={name}
          colProps={colProps ?? { xs: 12, sm: 6 }}
          label={label}
          type="phone"
          onSetState={this.handleContactState}
          modelPath={modelPath}
          {...formInputProps}
        />
      )
    }

    if (name === "email") {
      return (
        <FormInput
          key={name}
          readOnly={readOnly}
          obj={contact}
          field={name}
          colProps={colProps ?? { xs: 12 }}
          label={label}
          type="email"
          onSetState={this.handleContactState}
          modelPath={modelPath}
          {...formInputProps}
        />
      )
    }

    if (name === "linkedIn") {
      return (
        <FormInput
          key={name}
          readOnly={readOnly}
          obj={contact}
          field={name}
          colProps={colProps ?? { xs: 12 }}
          label={label ?? "LinkedIn"}
          onSetState={this.handleContactState}
          modelPath={modelPath}
          {...formInputProps}
        />
      )
    }

    if (["facebook", "instagram"].includes(name)) {
      return (
        <FormInput
          key={name}
          readOnly={readOnly}
          obj={contact}
          field={name}
          colProps={colProps ?? { xs: 12 }}
          label={label}
          onSetState={this.handleContactState}
          modelPath={modelPath}
          {...formInputProps}
        />
      )
    }

    if (name === "extension") {
      return contactsExtensionFields.map((formInputProps, key) => (
        <FormInputExtended key={key} {...formInputProps} readOnly={readOnly} obj={contact} onSetState={this.handleContactState} />
      ))
    }

    if (name === "isDefault") {
      return (
        <FormInput
          key={name}
          readOnly={readOnly}
          obj={contact}
          field={name}
          colProps={colProps ?? { xs: 12 }}
          placeholder={label}
          onSetState={this.handleContactState}
          modelPath={modelPath}
          type="checkbox"
          showLabel={false}
          {...formInputProps}
        />
      )
    }

    if (typeof name === "string") {
      return (
        <Col key={name} {...colProps}>
          {name}
        </Col>
      )
    }

    return null
  }

  render() {
    const { readOnly, closeModal, rows, modelPath } = this.props
    const { contact } = this.state

    const defaultRows = [
      [
        "title",
        "firstName",
        "lastName",
        "secondName",
        "position",
        "phone",
        "mobile",
        "email",
        "linkedIn",
        "facebook",
        "instagram",
        "extension",
        "isDefault",
      ],
    ]

    const rowsContent = generateRows({
      getField: this.getField,
      rows: rows?.length ? rows : defaultRows,
      entity: contact,
      handleSetState: this.handleContactState,
      modelPath,
      readOnly,
    })

    return (
      <Modal show={true} onHide={closeModal} backdrop="static" className="person-contact">
        <Modal.Header closeButton>
          <Modal.Title>{loc`Contact`}</Modal.Title>
        </Modal.Header>

        <Modal.Body>{rowsContent}</Modal.Body>

        <Modal.Footer>
          <CustomButton bsSize="sm" onClick={closeModal} label={readOnly ? "Close" : "Cancel"} />

          {!readOnly && <CustomButton bsStyle="primary" bsSize="sm" fill onClick={this.saveContact} label="Save" />}
        </Modal.Footer>
      </Modal>
    )
  }
}

export default PersonContactModal
