import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import { Button } from "react-bootstrap"
import { withRouter } from "react-router-dom"

import { loc } from "@/_services/localization"
import { handleAccessibleOnKeyDown, isNewTabOrWindowClick } from "@/_services/utils"

class CustomButton extends React.Component {
  getLinkTo = async () => {
    let { linkTo } = this.props
    if (!linkTo) return

    if (typeof linkTo === "function") {
      const url = await linkTo()
      if (url) linkTo = url
    }

    if (linkTo && typeof linkTo === "string") return linkTo
  }

  // Open in a new tab when clicking the mouse middle button
  handleMouseUp = async event => {
    if (!isNewTabOrWindowClick(event)) return
    const linkTo = await this.getLinkTo()
    if (linkTo) window.open(window.location.origin + linkTo, "_blank")
  }

  handleClick = async event => {
    const { onClick, history } = this.props
    if (onClick) await onClick(event)
    else {
      if (isNewTabOrWindowClick(event)) return
      const linkTo = await this.getLinkTo()
      if (linkTo) history.push(linkTo)
    }
  }

  render() {
    let {
      fill,
      simple,
      pullRight,
      block,
      wd,
      round,
      icon,
      neutral,
      className = "",
      disabled,
      image,
      label,
      loading,
      iconClassName,
      hidden,
      children,
      // below props are unused but declared so they aren't mounted on the button tag
      match, // eslint-disable-line
      location, // eslint-disable-line
      history, // eslint-disable-line
      staticContext, // eslint-disable-line
      linkTo, // eslint-disable-line
      onClick,
      elementTag,
      bsSize,
      bsStyle,
      ...rest
    } = this.props

    if (hidden) return null

    const btnClasses = classNames({
      "btn-fill": fill,
      "btn-simple": simple,
      "pull-right": pullRight,
      "btn-block": block,
      "btn-wd": wd,
      "btn-round": round,
      "btn-icon": icon,
      "btn-neutral": neutral,
    })

    disabled = disabled ? true : false
    if (loading && !disabled) disabled = loading

    const locLabel = loc(label)
    const innerChildren = (
      <>
        {!loading && (iconClassName || image) && <i className={iconClassName || image} />}
        {loading && <i className="icn-circle-notch icn-spin icn-xs mr-5px" />}
        {locLabel}
        {children}
      </>
    )

    const allProps = {
      className: `inline-flex-center ${btnClasses} ${className}`,
      ["aria-label"]: locLabel,
      disabled,
      onClick: this.handleClick,
      onKeyDown: event => {
        if (onClick) {
          handleAccessibleOnKeyDown({ event, history, linkTo, fn: this.handleClick })
        }
      },
      onMouseUp: this.handleMouseUp,
      bsSize,
      bsStyle,
      // should be at then end to allow overriding previous props
      ...rest,
    }

    if (elementTag === "div") {
      return (
        <div {...allProps} className={`${allProps.className} btn btn-${bsSize} btn-${bsStyle}`}>
          {innerChildren}
        </div>
      )
    }

    return <Button {...allProps}>{innerChildren}</Button>
  }
}

// hack to remove useless warning when passing actual colors to bootstrap button
Button.propTypes.bsStyle = PropTypes.oneOf([
  "success",
  "warning",
  "error",
  "primary",
  "info",
  "default",
  "danger",
  "green",
  "yellow",
  "orange",
  "red",
  "purple",
  "blue",
  "gray",
  "neutral",
  "success btn-fill",
  "warning btn-fill",
  "error btn-fill",
  "primary btn-fill",
  "info btn-fill",
  "default btn-fill",
  "danger btn-fill",
  "green btn-fill",
  "yellow btn-fill",
  "orange btn-fill",
  "red btn-fill",
  "purple btn-fill",
  "blue btn-fill",
  "gray btn-fill",
  "neutral btn-fill",
])

// this generates useless warning when we pass a non null but non boolean value
// CustomButton.propTypes = {
// fill: PropTypes.bool,
// simple: PropTypes.bool,
// pullRight: PropTypes.bool,
// block: PropTypes.bool,
// wd: PropTypes.bool,
// round: PropTypes.bool,
// tag: PropTypes.bool,
// icon: PropTypes.bool,
// neutral: PropTypes.bool,
// twitter: PropTypes.bool,
// facebook: PropTypes.bool,
// google: PropTypes.bool,
// linkedin: PropTypes.bool,
// pinterest: PropTypes.bool,
// youtube: PropTypes.bool,
// tumblr: PropTypes.bool,
// github: PropTypes.bool,
// behance: PropTypes.bool,
// dribbble: PropTypes.bool,
// reddit: PropTypes.bool,
// stumbleupon: PropTypes.bool,
// }

export default withRouter(CustomButton)
