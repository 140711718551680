import React from "react"
import Chartist from "chartist"
import ChartistGraph from "react-chartist"
import { Row, Col } from "react-bootstrap"

import Card from "@/_components/Card"
import PersonLink from "@/_components/PersonLink"
import GeneratePaymentFileButton from "@/billing/GeneratePaymentFileButton"
import { getEntities, searchEntities } from "@/_services/utils"
import { getPayerPayeeValues } from "@/_services/personUtils"
import { dateNoTime, truncate } from "basikon-common-utils"
import { loc } from "@/_services/localization"

const cashflow = {
  // <EntitiesPage/>
  columns: [
    { title: "Registration", name: "registration", linkTo: "/cashflow/{registration}" },
    { title: "Organization", name: "organization.name", hidden: true },
    { title: "Status", name: "status", select: "cashflowStatus", badge: true },
    { title: "Date", name: "paymentDate", type: "date" },
    { title: "Type", name: "type", select: "cashflowType" },
    { title: "Invoice", name: "invoice", linkTo: "/invoice/{invoiceRegistration}" },
    { title: "Contract", name: "contractRegistration", linkTo: "/contract/{contractRegistration}" },
    { title: "ContractLot", name: "contractLot", linkTo: "/contract-lot/{contractLotRegistration}", hidden: true },
    { title: "Asset", name: "asset", linkTo: "/asset/{assetRegistration}", hidden: true },
    { title: "AssetLot", name: "assetLot", linkTo: "/asset-lot/{assetLotRegistration}", hidden: true },
    { title: "Payer", name: "payer" },
    { title: "Payee", name: "payee" },
    { title: "Person", name: "payerOrPayee" },
    { title: "Method", name: "method", select: "paymentMode" },
    { title: "Iban", name: "bankIban" },
    { title: "Amount", name: "amount", excelFormat: "currency", type: "currency", currencyPath: "currency" },
  ],
  searchFields: getSearchFields(),
  formatTableData,
  postFetch,
  cardFooter,
  cardPostComponents,
}

function cardFooter({ pageConfig }) {
  return (
    !pageConfig?.hideGeneratePaymentFileButton && (
      <Row>
        <Col xs={12}>
          <GeneratePaymentFileButton />
        </Col>
      </Row>
    )
  )
}

function cardPostComponents({ state }) {
  const { dataBar } = state

  return (
    <Row>
      <Col xs={12}>
        <Card title={loc`History`}>
          <Row>
            <Col xs={12}>
              <ChartistGraph
                data={dataBar}
                type="Line"
                options={{
                  fullWidth: true,
                  // Within the series options you can use the series names
                  // to specify configuration that will only be used for the
                  // specific series.
                  series: {
                    "series-1": {
                      lineSmooth: Chartist.Interpolation.simple(),
                      showPoint: false,
                    },
                    "series-2": {
                      lineSmooth: Chartist.Interpolation.step(),
                      showPoint: false,
                      showLine: false,
                      showArea: true,
                    },
                  },
                }}
                responsiveOptions={[]}
              />
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  )
}

function formatTableData(data) {
  return data.map(cashflow => {
    const { payerRegistration, payerName, payerBankAccount = {}, payeeRegistration, payeeName, payeeBankAccount = {} } = cashflow

    const payerLink = <PersonLink registration={payerRegistration}>{truncate(payerName, 17) || payerRegistration}</PersonLink>
    const payeeLink = <PersonLink registration={payeeRegistration}>{truncate(payeeName, 17) || payeeRegistration}</PersonLink>
    let invoiceRegistration = cashflow.invoiceRegistration || cashflow.allocations?.[0]?.invoiceRegistration
    let invoice = invoiceRegistration
    if (!cashflow.allocations?.every(it => it.invoiceRegistration === invoiceRegistration)) {
      invoiceRegistration = null
      invoice = loc`Multiple`
    }

    return {
      ...cashflow,
      id: cashflow.registration,
      invoice,
      invoiceRegistration,
      payer: payerLink,
      payee: payeeLink,
      payerOrPayee: cashflow.pr === "R" ? payerLink : payeeLink,
      bankIban: cashflow.pr === "R" ? payeeBankAccount.bankIban : payerBankAccount.bankIban,
    }
  })
}

async function postFetch({ state }) {
  const { entities: cashflows } = state

  const dataBar = computeDataBar(cashflows)
  await includePayerPayeeNames(cashflows)

  return { dataBar, cashflows }
}

async function includePayerPayeeNames(cashflows) {
  // Fetch persons in order to show their names in table
  const personRegistrations = Array.from(
    new Set(
      cashflows
        .map(cashflow => [!cashflow.payeeName && cashflow.payeeRegistration, !cashflow.payerName && cashflow.payerRegistration])
        .flat()
        .filter(r => r && r !== "TENANT"),
    ),
  )
  if (!personRegistrations.length) return

  const persons = await getEntities("person", { registration: personRegistrations.join(","), projection: "registration,name" })

  const personsMap = new Map()
  for (const person of persons) personsMap.set(person.registration, person.name)

  for (const cashflow of cashflows) {
    if (!cashflow.payerName && cashflow.payerRegistration) cashflow.payerName = personsMap.get(cashflow.payerRegistration)
    if (!cashflow.payeeName && cashflow.payeeRegistration) cashflow.payeeName = personsMap.get(cashflow.payeeRegistration)
  }
}

function computeDataBar(cashflows) {
  const datedCashflows = cashflows.filter(it => it.paymentDate)

  let minDate = new Date()
  let maxDate = new Date()
  datedCashflows.forEach(it => {
    if (minDate > it.paymentDate) minDate = it.paymentDate
    if (maxDate < it.paymentDate) maxDate = it.paymentDate
  })

  let maxYear = maxDate.getFullYear()
  let maxMonth = maxDate.getMonth()
  let year = minDate.getFullYear()
  let month = minDate.getMonth()
  let yearlyMonths = {}
  let flatMonths = []
  for (;;) {
    let strYear = year.toString()
    yearlyMonths[strYear] = yearlyMonths[strYear] || {}
    let obj = {
      date: dateNoTime(new Date(year, month, 1)),
      amount: 0,
      balance: 0,
    }
    yearlyMonths[strYear][month.toString()] = obj
    flatMonths.push(obj)
    month++
    if (month === 12) {
      month = 0
      year++
    }
    if (year > maxYear || (year === maxYear && month > maxMonth)) break
  }

  for (const cashflow of datedCashflows) {
    const obj = yearlyMonths[cashflow.paymentDate.getFullYear().toString()][cashflow.paymentDate.getMonth().toString()]

    if (cashflow.pr === "P") obj.amount -= cashflow.amount
    else obj.amount += cashflow.amount || 0
  }

  let balance = 0
  flatMonths.forEach(it => {
    balance += it.amount || 0
    it.balance = balance
  })

  return {
    labels: [],
    series: [
      { name: "series-1", data: flatMonths.map(it => it.balance) },
      { name: "series-2", data: flatMonths.map(it => it.amount) },
    ],
  }
}

function getSearchFields() {
  const quickSearchPersons = async search => {
    let persons = await searchEntities("person", search, { roles: "-SALES" })
    return getPayerPayeeValues(
      null,
      persons.map(it => ({ personRegistration: it.registration, person: it })),
      { richValuesList: true },
    )
  }

  return [
    [
      { field: "registration", colProps: { xs: 12, sm: 4 }, regex: true },
      { field: "description", colProps: { xs: 12, sm: 8 }, regex: true },
    ],
    [
      { field: "invoice", colProps: { xs: 12, sm: 4 } },
      { field: "status", type: "multiple", select: "cashflowStatus", colProps: { xs: 12, sm: 8 } },
    ],
    [
      {
        field: "contract",
        searchEntityName: "Contract",
        placeholder: "Type to search for a contract",
        actionHidden: true,
        regex: true,
        colProps: { xs: 12, sm: 3 },
      },
      { field: "contractLot", colProps: { xs: 12, sm: 3 } },
      { field: "asset", colProps: { xs: 12, sm: 3 } },
      { field: "assetLot", colProps: { xs: 12, sm: 3 } },
    ],
    [
      { field: "sepa", label: loc`SEPA`, colProps: { xs: 12, sm: 4 } },
      { field: "method", type: "multiple", select: "paymentMode", colProps: { xs: 12, sm: 4 } },
      { field: "type", type: "multiple", select: "cashflowType", colProps: { xs: 12, sm: 4 } },
    ],
    [
      { field: "internalReference", colProps: { xs: 12, sm: 6 } },
      { field: "externalReference", colProps: { xs: 12, sm: 6 } },
    ],
    [
      { field: "payer", select: quickSearchPersons, colProps: { xs: 12, sm: 12, md: 6, lg: 6 } },
      { field: "payee", select: quickSearchPersons, colProps: { xs: 12, sm: 12, md: 6, lg: 6 } },
    ],
    [
      { field: "pr", label: loc`Payable/Receivable`, select: "invoicePr", colProps: { xs: 12, sm: 12, md: 3 } },
      { field: "bankIban", label: loc`IBAN`, colProps: { xs: 12, sm: 12, md: 9 } },
    ],
    [
      { field: "minAmount", type: "currency", label: loc`Amount (min)`, colProps: { xs: 12, sm: 3 } },
      { field: "maxAmount", type: "currency", label: loc`Amount (max)`, colProps: { xs: 12, sm: 3 } },
      { field: "fromPaymentDate", type: "date", label: loc`From date`, colProps: { xs: 12, sm: 3 } },
      { field: "toPaymentDate", type: "date", label: loc`To date`, colProps: { xs: 12, sm: 3 } },
    ],
  ]
}

export default cashflow
