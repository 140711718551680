import decline from "./declension/index.mjs"

/**
 * @param {string} str
 * @returns Returns the provided string with the first letter capitalized.
 */
function capitalizeFirstLetter(str) {
  if (!str) return str
  return str.charAt(0).toUpperCase() + str.slice(1)
}

/**
 * @param {string} str
 * @returns Returns the provided string with the first letter in lower case.
 */
function lowerCaseFirstLetter(str) {
  if (!str) return str
  return str.charAt(0).toLowerCase() + str.slice(1)
}

function truncate(str, maxLength = 25, ellipsis = "...") {
  if (!str) return
  if (str.length <= maxLength + 3) return str
  return str.substring(0, maxLength) + ellipsis
}

/**
 * Replace 8239 and 160 character codes by 32 " " (normal space)
 * in order to avoid problems with replace regex function
 */
function replaceASCII(str) {
  const arr = []
  if (str) {
    for (let i = 0; i < str.length; i++) {
      if (str.charCodeAt(i) === 8239 || str.charCodeAt(i) === 160) arr.push(" ")
      else arr.push(str.charAt(i))
    }
  }
  return arr.join("")
}

/**
 * @param {string} string
 * @param {size=2} size
 * @returns Returns the provided string right-padded with spaces up to the specified size.
 */
function padStringEnd(string = "", size) {
  while (string.length < size) string += " "
  return string
}

/**
 * @param {string} str
 * @returns Returns the given string stripped of spaces and tabs and trimmed.
 */
function trim(str) {
  if (!str || typeof str !== "string") return str
  return str.replace(/ /g, "").replace(/\t/g, "").trim()
}

/**
 * @param {string} word
 * @param {string} declension
 * @param {string} locale currently only supports lt-LT
 * @returns Returns the word declined.
 */
function declineWord(word, declension, locale) {
  if (!word || !declension || !locale) return word
  return decline(word, declension, locale.split("-")[0])
}

/**
 * @param {string} expression
 * @returns Returns the given camelCase expression as a sentence. Example: "theDogJumpedOverThePond" -> "The dog jumped over the pond"
 */
function labelFromName(name) {
  if (!name) return ""
  let label = ""
  for (let i = 0; i < name.length; i++) {
    if (i === 0) {
      label += name[0].toUpperCase()
    } else if (name[i] >= "A" && name[i] <= "Z") {
      label += " " + name[i].toLowerCase()
    } else {
      label += name[i]
    }
  }
  return label
}

export { capitalizeFirstLetter, lowerCaseFirstLetter, truncate, replaceASCII, padStringEnd, trim, declineWord, labelFromName }
